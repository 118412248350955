export function dropdown(view) {
  const dropdownBtn = view.querySelector('.dropdown__btn');
  const dropdownList = view.querySelector('.dropdown__list');
  let dropdownOpened = false;
  let dropdownCanClose = false;

  if (dropdownBtn || dropdownList) {
    dropdownBtn.addEventListener('click', () => {
      dropdownList.style.display = 'block';
      dropdownOpened = true;
    });

    document.addEventListener('click', () => {
      if (dropdownOpened && dropdownCanClose) {
        dropdownList.style.display = 'none';
        dropdownOpened = false;
        dropdownCanClose = false;
      }

      if (dropdownOpened) {
        dropdownCanClose = true;
      }
    });
  }
}
