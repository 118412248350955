const _ = require('lodash');

const SLIDE_FADE_DURATION = 1500;
const SLIDE_PAUSE_DURATION = 3000;

async function checkElementVisibility(element) {
  return new Promise((resolve, reject) => {
      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
              const isFullyInFrame = entry.intersectionRatio === 1;
              resolve(isFullyInFrame);
              observer.disconnect();
          });
      });

      observer.observe(element);
  });
};

async function getSlidesFromPrayers(requestList) {
  const prayers = Array.from(requestList.querySelectorAll('.request'));

  let amountPerSlide = 0;
  for (const prayer of prayers) {
    const isVisible = await checkElementVisibility(prayer);
    if (isVisible) {
      amountPerSlide += 1;
    } else {
      prayer.remove(); // Remove prayers that are not visible
    }
  }

  return _.chunk(prayers, amountPerSlide);
}

function createSlideOutro() {
  const slide = document.createElement('div');
  slide.classList.add('slide-cover');

  const container = document.createElement('div');
  container.classList.add('slide-cover-container');
  slide.appendChild(container);

  const h1 = document.createElement('h1');
  h1.textContent = 'Submit your prayer requests';
  container.appendChild(h1);

  const h2 = document.createElement('h2');
  h2.textContent = 'prayer.thepop.church';
  container.appendChild(h2);

  return slide;
}

export default async (view) => {
  const playSlides = async (requestList, originalPrayers) => {   
    return new Promise(async (res) => {
      const prayerSlides = await getSlidesFromPrayers(requestList);
      const slideOutro = createSlideOutro();
      const slides = [...prayerSlides, [slideOutro]];

      let delay = 0;
      for (const slidePrayers of slides) {
        const sliderSlide = document.createElement('div');
        sliderSlide.classList.add('slide');
        sliderSlide.style.opacity = '0'; // reset opacity to 0
        sliderSlide.style.animation = `fadeIn ${SLIDE_FADE_DURATION}ms linear ${delay}ms`;
        requestList.appendChild(sliderSlide);
        delay += (SLIDE_FADE_DURATION * 2) + SLIDE_PAUSE_DURATION;
  
        const handleFadeComplete = (event) => {
          const isLastSlide = slides.indexOf(slidePrayers) === slides.length -1;

          if (event.animationName === 'fadeIn') {
            sliderSlide.style.opacity = '1';
            sliderSlide.style.animation = `fadeOut ${SLIDE_FADE_DURATION}ms linear ${SLIDE_PAUSE_DURATION}ms`;
          }
  
          if (event.animationName === 'fadeOut') {
            sliderSlide.style.opacity = '0';
  
            if (isLastSlide) {
              // Clear requestList after all slides are done
              requestList.innerHTML = '';
              for (const prayer of originalPrayers) {
                // Reset opacity to 0 before appending so that requests
                // aren't visible to the user when added into the DOM
                prayer.style.opacity = '0';
                requestList.appendChild(prayer);
              }
              res();
            }
          }
        };
        sliderSlide.addEventListener('animationend', handleFadeComplete);
  
        for (const prayerElement of slidePrayers) {
          prayerElement.style.opacity = '1';
          sliderSlide.appendChild(prayerElement);
        }
      }
    });
  }

  while (true) {
    try {
      const requestList = view.querySelector('.requestList');
      const originalPrayers = Array.from(requestList.querySelectorAll('.request'));

      await playSlides(requestList, originalPrayers);
    } catch (error) {
      console.error(error);
    }
  }
};
