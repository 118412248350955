const axios = require('axios');

export default function logout(view) {
  const logoutBtn = view.querySelector('.logout');

  if (logoutBtn) {
    logoutBtn.addEventListener('click', async () => {
      await axios.post('/logout');
      window.location = '/';
    });
  }
}
