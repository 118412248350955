export default (view) => {
  const submit = view.querySelector('#submit');
  const submittedTime = view.querySelector('#submittedTime');

  submit.addEventListener('click', () => {
    const currentTime = Date.now();

    submittedTime.value = currentTime;
  });

  function charsLeft(name) {
    const counter = view.querySelector(`#${name}Counter`);
    const input = view.querySelector(`#${name}`);
    const maxLen = input.getAttribute('maxlength');
    const inputLength = input.value.trim().length;

    counter.innerText = maxLen;

    if (inputLength) {
      counter.innerText = maxLen - inputLength;
    }

    if (inputLength === 0) {
      counter.style.display = 'none';
    } else {
      counter.style.display = 'inline';
    }

    if (counter.innerText === '0') {
      counter.style.color = 'red';
    } else {
      counter.style.color = '#8a8a8a';
    }
  }

  charsLeft('name');
  charsLeft('message');

  view.querySelector('#name').addEventListener('input', () => {
    charsLeft('name');
  });

  view.querySelector('#message').addEventListener('input', () => {
    charsLeft('message');
  });
};
