const { baseControls } = require('./baseControls');

export default (view) => {
  const users = view.querySelectorAll('.row');

  for (const user of users) {
    const inputArr = ['firstName', 'lastName', 'email', 'role'];
    baseControls(view, user, 'user', inputArr);
  }
};
