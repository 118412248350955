import proPresenterService from '../../../server/services/proPresenterService';

const axios = require('axios');
const { dropdown } = require('../dropdown');

function enableSaveForDirtyInputs(view) {
  const saveBtn = view.querySelector('.save');
  const dirtyFound = view.querySelector('.row.dirty');

  if (dirtyFound) {
    saveBtn.disabled = false;
  } else {
    saveBtn.disabled = true;
  }
}

function checkInputForNewValues(view, row, inputArr) {
  for (const input of inputArr) {
    const selector = row.querySelector(`.${input}`);
    const initialVal = selector.value;

    selector.addEventListener('input', () => {
      if (selector.value !== initialVal) {
        row.classList.add('dirty');
        selector.classList.add('dirty');
      } else {
        row.classList.remove('dirty');
        selector.classList.remove('dirty');
      }

      enableSaveForDirtyInputs(view);
    });
  }
}

function saveChanges(view, type, inputArr) {
  const saveBtn = view.querySelector('.save');

  saveBtn.addEventListener('click', async () => {
    const dirties = view.querySelectorAll('.row.dirty');

    for (const dirty of dirties) {
      const dataObj = {};
      const id = dirty.dataset.id;

      for (const input of inputArr) {
        const inputVal = dirty.querySelector(`.${input}`).value;

        dataObj[input] = inputVal;
      }

      await axios.put(`/${type}/${id}`, dataObj);

      await proPresenterService.updateMessage();

      window.location.reload(true);
    }
  });
}

function deleteRow(row, type) {
  const deleteBtn = row.querySelector('.delete');
  const id = row.dataset.id;

  deleteBtn.addEventListener('click', async () => {
    await axios.delete(`/${type}/${id}`);

    await proPresenterService.updateMessage();

    window.location.reload(true);
  });
}

export function baseControls(view, row, type, inputArr) {
  dropdown(row);

  deleteRow(row, type);

  checkInputForNewValues(view, row, inputArr);
  saveChanges(view, type, inputArr);
};
