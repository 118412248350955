import proPresenterService from '../../../server/services/proPresenterService';

const axios = require('axios');
const { baseControls } = require('./baseControls');

function paramKeyInUrl(key) {
  const param = new Map(document.location.search.slice(1).split('&').map(kv => kv.split('=')));

  return param.has(key);
}

export default (view) => {
  const prayers = view.querySelectorAll('.row');
  const filter = view.querySelector('.filter');

  function changeFilter() {
    const options = filter.options;

    // Reset localStorage if filter key is in Url
    if (!paramKeyInUrl('filter')) {
      localStorage.removeItem('filter');
    }

    const storageFilter = localStorage.getItem('filter');
    if (storageFilter) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];

        if (option.value === storageFilter) {
          option.selected = true;
        }
      }
    }

    filter.addEventListener('change', () => {
      localStorage.setItem('filter', filter.value);

      window.location = `/admin?filter=${filter.value}`;
    });
  }

  changeFilter();

  for (const prayer of prayers) {
    const id = prayer.dataset.id;
    const approveCheckbox = prayer.querySelector('.approved');
    const pinBtn = prayer.querySelector('.pin');

    approveCheckbox.addEventListener('click', async () => {
      const approved = approveCheckbox.checked;

      await axios.put(`/prayer/${id}`, { approved });

      await proPresenterService.updateMessage();
    });

    pinBtn.addEventListener('click', async () => {
      const type = pinBtn.dataset.type;
      let pin = true;

      if (type === 'unpin') {
        pin = false;
      }

      await axios.put(`/prayer/${id}`, { pinned: pin });

      window.location.reload(true);
    });


    const inputArr = ['name', 'message'];
    baseControls(view, prayer, 'prayer', inputArr);
  }
};
