const axios = require('axios');

const config = {
  messageUuid: process.env.PRO_PRESENTER_MESSAGE_UUID,
  themeUuid: process.env.PRO_PRESENTER_THEME_UUID,
};

const defaultRequest = {
    id: {
      name: 'Prayer Requests',
    },
    tokens: [],
    theme: {
        name: 'Setlist - Vertical Scrolling',
        uuid: config.themeUuid,
    },
    visible_on_network: true,
};

function formatMessages(prayerRequests) {
    return prayerRequests.map(({ name, message }) => `${name}\n${message}\r\r`).join('');
}

class ProPresenterService {
  async getFormattedMessage() {
    try {
      const res = await axios.get('/prayers/approved');
      return formatMessages(res.data);
    } catch (error) {
      console.error('Error formatting message:', error.message);
      throw error;
    }
  }

  async updateMessage() {
    const message = await this.getFormattedMessage();
    
    try {
      const request = { ...defaultRequest, message };
      const res = await axios.post(`/pro-presenter/messages/${config.messageUuid}`, request);
      return res.data;
    } catch (error) {
      console.error('Error sending message:', error.message);
      throw error;
    }
  }
};

export default new ProPresenterService();