// Babel polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

require('./styles/main.scss');

import prayerForm from './scripts/prayerForm';
import prayerAnimatedFade from './scripts/prayerAnimatedFade';
import editPrayers from './scripts/admin/editPrayers';
import editUsers from './scripts/admin/editUsers';
import { dropdown } from './scripts/dropdown';
import logout from './scripts/logout';

// Scopes components to view
function scope(selector, cb) {
  const view = document.querySelector(selector);

  if (view) cb(view);
}

// Main
scope('.Header', (view) => {
  dropdown(view);
  logout(view);
});

scope('#HomeView', (view) => {
  prayerForm(view);
});

scope('#RequestsView', (view) => {
  // prayerAnimatedScroll(view);
  prayerAnimatedFade(view);
});


// Admin
scope('#EditPrayers', (view) => {
  editPrayers(view);
});

scope('#EditUsers', (view) => {
  editUsers(view);
});
